<template>
  <div>
    <CModalExtended
      :title="tituloModal"
      :closeOnBackdrop="false"
      color="dark"
      size="lg"
      :show.sync="CreModal"
    >
      <form class="company-form">
        <CRow>
          <CCol sm="11">
            <label><b class="text-danger">*</b>{{$t('label.type')}} {{$t('label.module')}}</label>
            <CSelect
              :placeholder= $t('label.type') + ' ' + $t('label. module') /// cambiar
              :horizontal="{label:'col-sm-9 col-lg-3',input:'col-sm-11 col-lg-9'}"
              addLabelClasses="text-right required"            
              invalid-feedback="* Campo Requerido"
            />
          </CCol>
          <CCol sm="12">
            <CRow align-vertical="center" class="mb-4">
              <CCol sm="8">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      invalid-feedback="Campo requerido."
                      size="sm"
                      label="TIPO GRUA"
                      addLabelClasses="required"
                      placeholder="TIPO GRUA"
                      maxlength="100"
                    />
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      placeholder="0"
                      invalid-feedback="Campo requerido."
                      size="sm"
                      label="MAX HEIGHT(Mts)"
                      addLabelClasses="required"
                      maxlength="20"
                    />
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      placeholder="0"
                      invalid-feedback="Campo requerido."
                      size="sm"
                      label="MAX WEIGHT(TON)"
                      addLabelClasses="required"
                      maxlength="20"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </form>
    </CModalExtended>
  </div>
</template>

<script>
import { DateFormater, tableTextHelpers } from "@/_helpers/funciones";
import General from "@/_mixins/general";


function busqEstructura() {
  this.itemsApi = [];

  let listado = Array;
  this.$http
    .get("Route-list", {})
    .then((response) => {
      listado = [...response.data.data];
      this.itemsApi = listado.map((listado) =>
        Object.assign({}, this.itemsApi, {
          Nro: listado.id,
          Module: listado.Module,
          Rutas: listado.routes,
          FileDirectory: listado.FileDirectory || 'N/A',
        })
      );
    })
    .catch((err) => {
      this.$notify({
        group: "container",
         title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.LoadingDocs = false;
    });
}


function selectTpModulo() {
  return this.selectTpModuloData.map((tpmodule) =>
    Object.assign({}, this.selectTpModuloData, {
      value: tpmodule.TpModuleId,
      label: tpmodule.TpModuleName,
    })
  );
}



//DATA
function data() {
  return {
    itemsDocs: [],
    CreModal: false,
    tituloModal: "Test",
    StandardDocumentDocRoute: "",
    tableText: Object.assign({}, tableTextHelpers),
  };
}

export default {
  name: "crear-ruta",
  data,
  mixins: [General],
  methods: {},
  props: {
    modal: Boolean,
  },
  watch: {
    modal: function(val) {
      this.CreModal = val;
    },
    CreModal: function(val) {
      this.$emit("update:modal", val);
    },
  },
  mounted() {},
};
</script>
